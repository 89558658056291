import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { 
    // SpinLoading,
     Result, Space, Tag, Image } from 'antd-mobile'
import { request } from '@/utils'
import './index.scss';
const TOKENKEY = 'cx315_token'
const Murl = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [loading, setLoading] = useState(true);
    const [bangm, setbangm] = useState([]);


    useEffect(() => {
        async function getau() {
            const res = await request.post('/p_qrcode_res', params)
            // console.log(JSON.stringify(res))
            if (res.return_code === "success") {
                localStorage.setItem(TOKENKEY, JSON.stringify(res))

                setTimeout(() => { navigate('/f/index')
            
                setbangm(res)
                setLoading(false)
            }, 2000)

            }

            
        }

        getau()

    }, [params, navigate])
    return <>

        {loading && <div
            className='SpinLoading'>
            <Space justify='center' align='center' direction='vertical'>
                {/* <SpinLoading color='primary' style={{ '--size': '48px' }} /> */}
                <Image src='http://d.ddspp.com:30123/1000_1734880838#sc ' />


                <Tag
                    color='primary'
                    fill='outline'
                    style={{ '--border-radius': '6px' }}
                >
                    数据加载中...
                </Tag>
            </Space>
            <Space justify='center' block>

            </Space>



        </div>

        }


        {bangm.return_code === "F" && <Result
            status='warning'
            title={bangm.return_msg}
        // description='内容详情可折行，建议不超过两行建议不超过两行建议不超过两行'
        />
        }

    </>
}

export default Murl;